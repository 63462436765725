export class GeneralPermissions {
    static readonly MANAGE_ROLE = 'ROLE_MANAGE_ROLE';
    static readonly ROLE_VIEW = 'ROLE_ROLE_VIEW';

    static readonly USER_VIEW = 'ROLE_USER_VIEW';
    static readonly USER_MANAGE = 'ROLE_USER_MANAGE';
    static readonly USER_MANAGE_COURSE_ROLE = 'ROLE_USER_MANAGE_COURSE_ROLE';

    static readonly LANGUAGE_MANAGE = 'ROLE_LANGUAGE_MANAGE';
    static readonly LANGUAGE_VIEW = 'ROLE_LANGUAGE_VIEW';

    static readonly CONTACT_US_VIEW = 'ROLE_CONTACT_US_VIEW';
    static readonly CURRENCY_VIEW = 'ROLE_CURRENCY_VIEW';
    static readonly CURRENCY_MANAGE = 'ROLE_CURRENCY_MANAGE';

    static readonly PAYMENT_ORDER_VIEW = 'ROLE_PAYMENT_ORDER_VIEW';
    static readonly UNIVERSITY_PAY_MANUAL = 'ROLE_UNIVERSITY_PAY_MANUAL';
    static readonly UNIVERSITY_PAY_MANUAL_VIEW_ENROLLMENT = 'ROLE_UNIVERSITY_PAY_MANUAL_VIEW_ENROLLMENT';

    static readonly SEND_TEST_MAIL = 'ROLE_SEND_TEST_MAIL';
    static readonly MAINTENANCE = 'ROLE_MAINTENANCE';

    static readonly COURSE_REPORT_VIEW = 'ROLE_COURSE_REPORT_VIEW';
    static readonly COURSE_REPORT_EXPORT_EXCEL = 'ROLE_COURSE_REPORT_EXPORT_EXCEL';

    static readonly IMPORT_EXCEL_DEBTORS_GEDUX = 'ROLE_IMPORT_EXCEL_DEBTORS_GEDUX';

    static readonly ADMIN_ROLE = 'ROLE_ADMIN';
}
