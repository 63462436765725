<div class="" style="display: flex; justify-content: center; width: 100%; background: white; align-items: center;">
    <!-- Main -->
    <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10">
        <!-- @formatter:off -->
        <div class="w-full max-w-3xl">
            <img src="assets/mantenimiento/mant1.jpg">
        </div>
        <!-- @formatter:on -->
        <div class="sm:mt-24 text-4xl md:text-7xl font-extrabold tracking-tight leading-tight md:leading-none text-center">En estos momentos estamos realizando mantenimiento en el sistema</div>
        <div class="mt-4 text-lg md:text-xl font-medium tracking-tight text-center text-secondary font-extrabold">En la brevedad quedará listo para su uso. <a
            class="mt-12 font-medium underline text-primary-500 text-end"  (click)="inicio()" style="color: #0d47a1; cursor: pointer">
            Ir al Inicio
        </a></div>

    </div>
</div>
