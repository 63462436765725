import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Location} from '@angular/common';

@Component({
    selector: 'app-f-error-dialog',
    templateUrl: 'f-error-dialog.component.html',
})
export class FErrorDialogComponent {
    title: string;
    message: any;
    btnLabel = 'Cerrar';
    private readonly defaultTitle = 'Error'; // Título fijo

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private location: Location) {
        this.message = data.message; // Asigna el mensaje recibido
        // Si se pasa un título, lo usa; de lo contrario, usa el título fijo
        this.title = data.title || this.defaultTitle; // Usar el título fijo si no se pasa uno
    }

    // tslint:disable-next-line:typedef
    onClose() {
        this.location.back();
    }
}
