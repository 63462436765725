import {FuseConfig} from '@fuse/types';
import {APP_CONFIG} from "../app.config";
import {ModeApplication} from "@quiox/global/models/modeApplication";

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'fuse-navy-700',
            secondaryBackground: 'fuse-navy-900',
            folded: false,
            hidden: setHiddenValues(),
            position: 'left',
            variant: 'vertical-style-2'
        },
        toolbar: {
            customBackgroundColor: false,
            background: 'fuse-white-500',
            hidden: setHiddenValues(),
            position: 'below-static'
        },
        footer: {
            customBackgroundColor: true,
            background           : 'fuse-navy-900',
            hidden               : true,
            position             : 'below-fixed'
        },
        sidepanel: {
            hidden  : false,
            position: 'right'
        }
    }
};

/**
 * Method to assign values to the hidden properties in fuseConfig and return true
 */
export function setHiddenValues(): boolean {
    const config = APP_CONFIG[APP_CONFIG.active_profile];
    const modeApplication = config.mode_application;
    console.log('Mantenimiento: ', modeApplication);
    return modeApplication == ModeApplication.MANTENIMIENTO;

    // fuseConfig.layout.navbar.hidden = navbarHidden;
    // fuseConfig.layout.toolbar.hidden = toolbarHidden;
    // fuseConfig.layout.footer.hidden = footerHidden;
    // fuseConfig.layout.sidepanel.hidden = sidepanelHidden;

}
