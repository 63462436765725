import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {AppRoutingModule} from './app-routing.module';
import {GoogleTagManagerModule, GoogleTagManagerService} from 'angular-google-tag-manager';
import {DefaultOAuthInterceptor} from "@quiox/global/interceptor/default-oauth.interceptor";
import {HttpErrorInterceptor} from "@quiox/global/interceptor/http-error.interceptor";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        AppRoutingModule,
        LayoutModule,
        GoogleTagManagerModule.forRoot({
            id: 'GTM-KC47KS',
        })
    ],
    providers: [        
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: RemoteErrorHandler,
        //     multi: true,
        //     deps: [Router]
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: DefaultI18nInterceptor,
        //     multi: true
        // },
        {
            provide: LOCALE_ID,
            useValue: 'es-US'
        },
        { provide: HTTP_INTERCEPTORS, useClass: DefaultOAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        {provide: 'googleTagManagerId', useValue: 'GTM-KC47KS'}, GoogleTagManagerService
    ],
    exports: [
        
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
