import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input, OnDestroy, OnInit,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';

import {FuseConfigService} from "../../@fuse/services/config.service";
import {AppUtils} from "@quiox/global/helpers/app.utils";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'app-mantenimiento',
    templateUrl: './mantenimiento.component.html',
    styleUrls: ['./mantenimiento.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class MantenimientoComponent {

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor(private router: Router, private route: ActivatedRoute, private _fuseConfigService: FuseConfigService,
                private changeDetectorRef: ChangeDetectorRef) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }

    };
    }





    // /**
    //  * On init
    //  */
    // ngOnInit(): void
    // {
    //     // Subscribe to config changes
    //     // Check if scrollbars enabled or not from the main config
    //     this._fuseConfigService.config
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe(
    //             (settings) => {
    //                console.log('Setting', settings);
    //             }
    //         );
    // }
    //
    // /**
    //  * On destroy
    //  */
    // ngOnDestroy(): void
    // {
    //     // Unsubscribe from all subscriptions
    //     this._unsubscribeAll.next();
    //     this._unsubscribeAll.complete();
    // }


    inicio(): void {
        // this.router.navigateByUrl('login');
        AppUtils.logout();
    }


}
