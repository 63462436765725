import {ModuleConfig} from '@quiox/global/config/config';
import {GeneralPermissions} from './general-permissions';

export const generalMenu: ModuleConfig = {
    id: 'general',
    name: 'General',
    fieldI18Key: 'NAV.GENERAL',
    menu: [
        {
            name: 'Roles',
            fieldI18Key: 'NAV.ROLES',
            url: '/general/roles',
            roles: [GeneralPermissions.ROLE_VIEW]
        },
        {
            name: 'Usuarios',
            fieldI18Key: 'NAV.USERS',
            url: '/general/users',
            roles: [GeneralPermissions.USER_MANAGE]
        },
        {
            name: 'Contactos',
            fieldI18Key: 'NAV.CONTACTS',
            url: '/general/contact',
            roles: [GeneralPermissions.CONTACT_US_VIEW]
        },
        {
            name: 'Idiomas',
            fieldI18Key: 'NAV.LANGUAGES',
            url: '/general/languages',
            roles: [GeneralPermissions.LANGUAGE_MANAGE]
        },
        {
            name: 'Monedas',
            fieldI18Key: 'NAV.CURRENCIES',
            url: '/general/currency',
            roles: [GeneralPermissions.CURRENCY_MANAGE]
        },
        {
            name: 'Correos de prueba',
            fieldI18Key: 'NAV.TESTMAIL',
            url: '/general/test-mail',
            roles: [GeneralPermissions.SEND_TEST_MAIL]
        },
        {
            name: 'Mantenimiento del Aplicativo',
            fieldI18Key: 'NAV.MAINTENANCE',
            url: '/general/maintenance',
            roles: [GeneralPermissions.MAINTENANCE]
        },
    ]
};
