import {ModuleConfig} from '@quiox/global/config/config';
import {GeneralPermissions} from 'app/main/general/config/general-permissions';

export const paymentMenu: ModuleConfig = {
    id: 'payment',
    name: 'Ingresos',
    // fieldI18Key: 'NAV.PAYMENT',
    menu: [
        /*{
            name: 'Órdenes de Pagos',
            // fieldI18Key: 'NAV.INVOICES',
            url: '/payment/orders',
            exactMatch: true,
            roles: [GeneralPermissions.PAYMENT_ORDER_VIEW]
        },
        {
            name: 'Conceptos de Pago',
            // fieldI18Key: 'NAV.INVOICES',
            url: '/payment/concept-orders',
            exactMatch: true,
            roles: [GeneralPermissions.PAYMENT_ORDER_VIEW]
        },*/
        {
            name: 'Estudiantes matriculados',
            fieldI18Key: 'NAV.students',
            url: '/university/income/',
            roles: [GeneralPermissions.UNIVERSITY_PAY_MANUAL_VIEW_ENROLLMENT]
        },
        {
            name: 'Importar deudores',
            fieldI18Key: 'NAV.debtor',
            url: '/university/import/',
            roles: [GeneralPermissions.IMPORT_EXCEL_DEBTORS_GEDUX]
        },
        {
            name: 'Sincronizar Matrícula',
            fieldI18Key: 'NAV.synchronize',
            url: '/university/synchronize-enrollment/',
            roles: [GeneralPermissions.IMPORT_EXCEL_DEBTORS_GEDUX]
        }
    ]
};
