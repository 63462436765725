import {AfterViewInit, Component, Inject, Injectable} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FocusTrap} from '@angular/cdk/a11y';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent
{
    public title = 'Confirmar';
    public confirmMessage: string;
    public sabana: any;
    public sabanaAnnouncement: any;
    public perfilUser: any;
    public selectedValue = 'todo';
    public selectedValueDefault = 'student';
    public student?: boolean;
    public teacher?: boolean;
    public coordinator?: boolean;
    private _focusTrap: FocusTrap; // Asegúrate de definir _focusTrap
    showCancel: boolean;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.showCancel = _data?.showCancel !== undefined ? _data.showCancel : true; // Por defecto, mostrar "Cancelar"
        // Manejo de caso donde _data es null
        if (!_data) {
            console.warn('Data for confirm dialog is null or undefined. Using default values.');
            this.student = false; // Asignar valores predeterminados
            this.teacher = false;
            this.coordinator = false;
            this.confirmMessage = 'No se proporcionó un mensaje de confirmación.'; // Mensaje predeterminado
        } else {
            this.student = _data.student || false;
            this.teacher = _data.teacher || false;
            this.coordinator = _data.coordinator || false;
            this.confirmMessage = _data.confirmMessage || '¿Está seguro de que desea continuar?'; // Mensaje de confirmación
        }

    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }

    /*ngAfterViewInit(): void {
        // Inicializa el FocusTrap aquí
        this._focusTrap = this.dialogRef.focusTrap; // Asegúrate de que esto sea correcto
        if (this._focusTrap) {
            this._focusTrap.focusInitialElement();
        } else {
            console.error('FocusTrap is not initialized');
        }
    }*/


}
