import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private _matSnackBar: MatSnackBar, private translateService: TranslateService) {
    }

    showMessage(i18KeyMessage?: string, textMessage?: string): void {
        const message = i18KeyMessage ? this.translateService.instant(i18KeyMessage) : textMessage;
        this._matSnackBar.open(message, 'OK', {
            verticalPosition: 'top',
            duration: 2000
        });
    }

    showMessageDontShowOK(i18KeyMessage?: string, textMessage?: string): void {
        const message = i18KeyMessage ? this.translateService.instant(i18KeyMessage) : textMessage;
        this._matSnackBar.open(message, null, {
            verticalPosition: 'top',
            duration: 2000
        });
    }

    private snackBarResponse: Subject<boolean> = new Subject<boolean>();

    showMessageConfirm(i18KeyMessage?: string, textMessage?: string): Observable<boolean> {
        const message = i18KeyMessage ? this.translateService.instant(i18KeyMessage) : textMessage;
        this._matSnackBar.open(message, 'OK', {
            verticalPosition: 'top',
            duration: undefined // Puedes ajustar la duración si es necesario
        }).onAction().subscribe(() => {
            this.snackBarResponse.next(true); // Emitir true cuando se haga clic en 'OK'
        });

        return this.snackBarResponse.asObservable(); // Devuelve el observable
    }

    async showMessageAndWait(i18KeyMessage?: string, textMessage?: string): Promise<boolean> {
        const result = await this.showMessageConfirm(i18KeyMessage, textMessage).toPromise();
        return result; // Devuelve el resultado booleano
    }
}


