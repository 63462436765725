<h1 matDialogTitle>{{ title }}</h1>
<div mat-dialog-content>{{ confirmMessage }}</div>
<!--<div *ngIf="selectOptions && selectOptions.length > 0">
    <select>
        <option *ngFor="let option of selectOptions" [value]="option">{{ option }}</option>
    </select>
</div>-->
<br>
<div *ngIf="sabana">
    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50"
                    class="pr-4 ">
        <mat-select [(value)]="selectedValue" required>
            <mat-option value="todo">Todos</mat-option>
            <mat-option value="ENROLLED">Matriculados</mat-option>
            <mat-option value="DISENROLLED">Bajas</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="sabanaAnnouncement">
        <mat-radio-group [(ngModel)]="selectedValue" required style="display: flex; flex-direction: row;">
            <mat-radio-button value="todo" style="margin-right: 10px;">Todos</mat-radio-button>
            <mat-radio-button value="ENROLLED" style="margin-right: 10px;">Matriculados</mat-radio-button>
            <mat-radio-button value="DISENROLLED">Bajas</mat-radio-button>
        </mat-radio-group>
</div>

<div *ngIf="perfilUser">
    <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50"
                    class="pr-4 ">
        <mat-select [(value)]="selectedValueDefault" required>
            <mat-option value="student" *ngIf="student">Estudiante</mat-option>
            <mat-option value="teacher" *ngIf="teacher">Mentor</mat-option>
            <mat-option value="coordinator" *ngIf="coordinator">Coordinador</mat-option>
        </mat-select>

    </mat-form-field>
</div>

<div mat-dialog-actions class="pt-24"*ngIf="!perfilUser" >
    <button mat-raised-button class="mat-accent mr-16" [disabled]="!selectedValue" (click)="dialogRef.close(selectedValue)">Confirmar</button>
    <!--<button mat-button (click)="dialogRef.close(false)">Cancelar</button>-->
    <button mat-button *ngIf="showCancel" (click)="dialogRef.close(false)">Cancelar</button>
</div>

<div mat-dialog-actions class="pt-24"*ngIf="perfilUser" >
    <button mat-raised-button class="mat-accent mr-16" [disabled]="!selectedValueDefault" (click)="dialogRef.close(selectedValueDefault)">Confirmar</button>
    <button mat-button (click)="dialogRef.close(false)">Cancelar</button>
</div>