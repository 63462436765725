import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, throwError} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {DialogService} from './dialog.service';
import {FNavigationService} from './f-navigation.service';
import {AppUtils} from "@quiox/global/helpers/app.utils";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class RestClientErrorHandlerService {
    private showErrorAlert: boolean;

    constructor(private dialogService: DialogService,
                private navigationService: FNavigationService,
                private fuseProgressBarService: FuseProgressBarService,
                private router: Router,
                /*, private i18nService: I18nService*/) {
    }

    public handleError(httpErrorResponse: HttpErrorResponse, handleError = false): Observable<any> {
        this.fuseProgressBarService.hide();

        if (handleError) {
            return throwError(httpErrorResponse);
        }

        if (httpErrorResponse.url.indexOf('notification/user/count') > 0) {
            return of('notification-server-notfound');
        }


        let operationResult = null;
        if (httpErrorResponse.status === 418) {
            this.router.navigate(['/mantenimiento']);
        } else if (httpErrorResponse.status === 401) {
            //  this.navigationService.casAuth();
            AppUtils.logout();
        } else if (httpErrorResponse.status === 400) {
            if (httpErrorResponse.error && httpErrorResponse.error.data) {
                operationResult = httpErrorResponse.error.data;
                this.dialogService.showValidationErrorDialog(operationResult);
                this.showErrorAlert = false;
            }
            if (httpErrorResponse.error && httpErrorResponse.error.message) {
                operationResult = httpErrorResponse.error.message;
                this.dialogService.showErrorDialog(operationResult, 'Datos incorrectos');
                this.showErrorAlert = false;
            }
        } else if (httpErrorResponse.status === 403) {
            if (httpErrorResponse.error && httpErrorResponse.error.message) {
                operationResult = httpErrorResponse.error.message;
                this.dialogService.showErrorDialog(operationResult, 'core.accessDenied');
                this.showErrorAlert = false;
            }
        } else {
            operationResult = 'Error desconocido accediendo al servidor'; // this.i18nService.getText('unknownErrorAccessingServer');
            this.showErrorAlert = true;
        }

        if (this.showErrorAlert) {
            this.showErrorAlert = false;
            this.dialogService.showErrorDialog(operationResult);
            // this.dialogService.alertError(this.i18nService.getText('error'), operationResult)
            //     .subscribe(() => this.showErrorAlert = false);
        }

        return throwError(httpErrorResponse);
    }

    // public handleBlobError(httpErrorResponse: HttpErrorResponse) {
    //     return this.parseErrorBlob(httpErrorResponse).pipe(catchError((err) => this.handleError(err)));
    // }
    //
    // private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    //     const reader: FileReader = new FileReader();
    //     const obs = Observable.create((observer: any) => {
    //         reader.onloadend = () => {
    //             let errResponse = _.clone(err);
    //             errResponse.error = JSON.parse(reader.result);
    //             observer.error(errResponse);
    //             observer.complete();
    //         };
    //     });
    //     reader.readAsText(err.error);
    //     return obs;
    // }
}
